<div class="container-fluid">
  <div class="row justify-content-center align-items-center gap-5">
    <div
      class="col-lg-4 col-md-4 h-50 justify-content-center align-items-center"
    >
      <img [src]="templateData.imgSrc" alt="Image" class="h-100 p-1" />
    </div>
    <div
      class="col-lg-4 col-md-4 col-sm-6 col-xs-12 h-50 mx-5 mt-3 pt-5 text-start d-flex flex-column"
    >
      <h1 class="flex-grow-1">{{ templateData.h1 }}</h1>
      <p class="text-wrap col-12 mt-2 flex-grow-1">
        {{ templateData.p }}
      </p>
      <button
        class="btn btn-link p-0 pb-5 align-self-start flex-grow-1"
        (click)="pageReload()"
      >
        {{ templateData.button }}
      </button>
    </div>
  </div>
</div>