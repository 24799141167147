import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})
export class ErrorComponent {
  errorType: string | undefined;
  originalUrl: string | undefined;

  templateData: templateData = {
    h1: '',
    p: '',
    button: '',
    imgSrc: '',
  };
  constructor(private route: ActivatedRoute,private title: Title, private meta: Meta, @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize(" Intercargo Logistics") );

    if (isPlatformBrowser(this.platformId)) {
    this.originalUrl = window.location.href; // Capture the current URL
    }
    this.errorType = this.route.snapshot.data['errorType'] || 'error404';
    if (this.errorType === 'Connection Lost') {
      this.templateData.h1 = 'Connection Lost';
      this.templateData.p =
        'Your connection to the server has been lost. Please try to reload the page.';
      this.templateData.button = 'Reload Website';
      this.templateData.imgSrc = '../../../assets/imgs/Gif/ConnectionLost.gif';
    } else {
      this.templateData.h1 = 'Error 404';
      this.templateData.p =
        'The page you are looking for does not exist. Please try to reload the page.';
      this.templateData.button = 'Go to Home Page';
      this.templateData.imgSrc = '../../../assets/imgs/Gif/404.gif';
    }
  }

  pageReload() {
    if (isPlatformBrowser(this.platformId)) {
    window.location.href = '/';
    }
  }
}
interface templateData {
  h1: string;
  p: string;
  button: string;
  imgSrc: string;
}